<template>
  <el-collapse v-model="filter" @change="handleChange">
    <el-collapse-item title="Фильтр" name="p1">
        <template v-slot:title>
          <span>
            <span>Фильтр</span>
            <el-button class="ml-5"
                plain
                size="mini"
                type="default" 
                @click.stop="clearFilters" 
                style="font-family: Montserrat-Medium !important;">
              Очистить фильтры
            </el-button>
          </span>
        </template>
      <el-row :gutter="10" class="mt-0 filter">
        <el-col :span="6" class="ml-2 filter--container">Наименование:</el-col>
        <el-col :span="6" class="ml-0 filter--container">ДЦ:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Владелец / эксплуатирующая организация:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Энергосистема:</el-col>
      </el-row>

      <el-row class="m-0 filter"  :gutter="10">
        <el-col :span="6" class="ml-2 filter--container">
          <remote-search-input
            id="energy_objects-filter--name_input"
            v-model="filterModel.nameFilter"
            searchUrl="/api/energyObjects/SearchByName"
          />
        </el-col>

        <el-col :span="6" class="ml-0 filter--container">
          <remote-search-input
            my-style="min-width:37rem;"
            v-model="filterModel.dcOrganizationFilter"
            searchUrl="/api/organizations/SearchByDC"
            return-prop="id"
            :allow-create=false
            :multiple=true
            :start-length=3
          />
        </el-col>

        <el-col :span="6" class="ml-0 filter--container">
          <remote-search-input
            v-model="filterModel.ownerOrganizationFilter"
            searchUrl="/api/organizations/SearchByOwner"
            return-prop="id"
            :allow-create=false
            :multiple=true
            :start-length=3
          />
        </el-col>

        <el-col :span="6" class="ml-0 filter--container">
          <!-- <remote-search-input size="small"
            v-model="filterModel.eoRegionFilter"
            searchUrl="/api/regions/SearchByName"
            :allow-create=false
            :multiple=true
            :start-length=3
            returnProp="id"
          />           -->
          <el-select multiple filterable clearable size="small"
                     ref="eoRegionFilterSelector"
                     :collapse-tags="filterModel.eoRegionFilter.length > 5"
                      v-model="filterModel.eoRegionFilter"
                      popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.eoRegionFilter = getSelectedIdsByName(operationalZones, $refs.eoRegionFilterSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in operationalZones"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row class="mt-2 filter" :gutter="10">
        <el-col :span="6" class="ml-2 filter--container">Тип:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Класс напряжения:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Отображать объекты локальной НСИ:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Скрыть зарубежные объекты::</el-col>
      </el-row>

      <el-row class="m-0 filter"  :gutter="10">
        <el-col :span="6" class="ml-2 filter--container">
          <el-select multiple filterable clearable size="small"
                     ref="energyObjectPsrTypesSelector"
                     v-model="filterModel.energyObjectTypeIdFilter"
                     :collapse-tags="filterModel.energyObjectTypeIdFilter.length > 3"
                     popper-class="search-select">
              <div class="pl-3">
                <el-button type="text" size="small"
                           @click="filterModel.energyObjectTypeIdFilter = getSelectedIdsByName(energyObjectPsrTypes, $refs.energyObjectPsrTypesSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
            <el-option
              v-for="item in energyObjectPsrTypes"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-0 filter--container">
          <el-select multiple filterable clearable size="small"
                     ref="baseVoltageSelector"
                     :collapse-tags="filterModel.baseVoltageFilter.length > 5"
                     v-model="filterModel.baseVoltageFilter">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.baseVoltageFilter = getSelectedIdsByName(sortedDescBaseVoltages, $refs.baseVoltageSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in sortedDescBaseVoltages"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-0 filter--container">
          <el-switch style="width: 100%;"
                v-model="filterModel.isLocalNsiFilter"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
        </el-col>

        <el-col :span="6" class="ml-0 filter--container">
          <el-switch style="width: 100%;"
                v-model="filterModel.isRuFilter"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
        </el-col>
      </el-row>
      <!-- <el-row class="m-0 filter">
        <el-col :span="8" class="filter--container ml-2 ">
          <el-button @click="clearFilters" type="text" style="font-family: Montserrat-Medium !important;">
            Очистить фильтры
          </el-button>
        </el-col>
      </el-row> -->
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import UserSettingsService from '@/service/userSettingsService';
import { mapGetters } from 'vuex';
import filterHelper from '@/mixins/filterHelper';

export default {
  name: 'EnergyObjectsFilter',
  props: {
    filterModel: { type: Object, default: () => {} },
  },
  mixins: [filterHelper],
  components: { RemoteSearchInput },
  data() {
    return {
      filter: [''],
    };
  },
  created() {
    if (UserSettingsService.has('energyObjectsFilter_filter_open', this.user.id)) {
      this.filter = ['p1'];
    }
  },
  computed: {
    ...mapGetters('identity', ['user']),
    ...mapGetters('dictionaries', ['energyObjectPsrTypes', 'baseVoltages', 'operationalZones']),
    sortedDescBaseVoltages() {
      const baVoWithDefault = [...this.baseVoltages, { id: '00000000-0000-0000-0000-000000000000', name: 'Без напряжения', code: 0 }];
      return baVoWithDefault.sort((a, b) => b.code - a.code);
    }
  },
  methods: {
    handleChange(val) {
      if (val.find((el) => el === 'p1')) {
        UserSettingsService.set('energyObjectsFilter_filter_open', true, this.user.id);
        this.$emit('active-change', true);
      } else {
        UserSettingsService.delete('energyObjectsFilter_filter_open', this.user.id);
        this.$emit('active-change', false);
      }
    },
    clearFilters() {
      this.$emit('clear-filters');
    }
  }
};
</script>

<style scoped>

</style>
